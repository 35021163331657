@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.header{
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.20) 0%, rgba(255, 255, 255, 0.20) 100%), linear-gradient(180deg, rgba(232, 254, 255, 0.80) 0%, rgba(255, 255, 255, 0.40) 100%);
  box-shadow: 0px 2px 12px 0px rgba(77, 82, 175, 0.08);
  backdrop-filter: blur(18px);
  position: fixed;
  top: 0;
  z-index: 99999;

  .wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding-block: 8px;
    @include responsive-sizing("padding-inline", 8, 32, 360, 1440);
  }

  .icon{
    width: 15px;
    height: 15px;
  }
}
.menu,.connect{
  @include flex-column();
  gap: 4px;
  @include media-max(1100px){
    gap: 0;
  }
}

.connect{
  align-items: flex-end;

  .button{
    transition: $transition-fast;
    span{
      color: #6A6ACF;
    }
    border: 1px solid transparent;
    background: #fff;

    &:hover{
      border-bottom: 1px solid #6A6ACF;
      background: #fff;
    }
  }
}

.nav{
  display: flex;
  align-items: center;
  gap: 60px;
  height: 100%;
}

.sidebar-content{
  background: #1C1B1F;
  height: 100%;
  padding: 16px;
  @include flex-column();
  gap: 24px;

  .logo{
    @include flex-center();
    width: 104px;
  }
}
.contacts{
    @include flex-center();
    gap: 16px;
    a{
      text-decoration: none;
      @include text-Body1();
      color: #49454F;
    }
}

