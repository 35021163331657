@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.navbar{
    width: 100%;
    height: 100%;
}
.items{
    display: flex;
    gap: 16px;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    .item{
        @include flex-column();
        position: relative;
        height: 100%;

        .open{
            opacity: 1;
            display: block;
        }
    }


    .link{
        text-decoration: none;
        color: #040F16;
        @include text-Body1();
        font-weight: 500;
        cursor:pointer;
        transition: $transition-fast;
        height: 100%;

        &:hover{
            transition: $transition-fast;
            color: rgba(86, 109, 214, 1);
            .icon{
                stroke: rgba(86, 109, 214, 1);
                transform: rotate(-90deg);
            }
        }
        &.link-icon{
            transition: $transition-fast;
            @include flex-center();
            gap: 4px;
            .icon{
                opacity: 0;
                visibility: hidden;
            }
            &:hover{
                .icon{
                    opacity: 1;
                    visibility: visible;
                    stroke: rgba(86, 109, 214, 1);
                    transform: rotate(-90deg);
                }
            }
        }

        &.submenu{
            display: flex;
            gap: 4px;
            align-items: center;
        }
        .icon{
            transition: 0.15s;
        }
    }
}
.subitems-wrapper{
    position: absolute;
    opacity: 0;
    display: none;
    transition: all 0.5s ease;
    padding: 24px 16px;
    width: 300px;
    z-index: 1;
    top: 37px;
    left: 0;
    border-radius: 32px;
    border: 1px solid rgba(14, 28, 54, 0.20);
    background: #fff;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);

    .subitems{
            display: flex;
            flex-direction: column;

            gap: 20px;

        .subitem{
            transition: $transition-fast;
            @include flex-row();
            align-items: center;

            &:hover{
                .icon{
                    stroke: rgba(86, 109, 214, 1);
                    transform: rotate(-90deg);
                }
                .link{
                    color: rgba(86, 109, 214, 1);

                }
            }
            .link{
                text-decoration: none;
                color: #040F16;
                font-family: "Nunito", sans-serif;
                font-size: 19px;
                font-style: normal;
                line-height: 120%;
                letter-spacing: -0.4px;
                font-weight: 500;
                cursor: pointer;
                transition: 0.3s all;
            }

            .icon{
                transition: 0.15s;
            }
        }

        &.submenu{
            display: flex;
            gap: 4px;
            align-items: center;
        }
    }
}

.navbar-mob{

}
.items-mob{
    @include flex-column();
    align-items: flex-start;
    gap: 16px;
    padding: 8px;
    .item-mob{
        @include flex-column();
        position: relative;
        height: 100%;
    }

    .link-mob{
        text-decoration: none;
        color: #ffffff;
        @include text-Body1();
        font-weight: 500;
        cursor:pointer;
        transition: $transition-fast;
        height: 100%;

        &:hover{
            transition: $transition-fast;
            color: rgba(86, 109, 214, 1);
            .icon-mob{
                stroke: rgba(86, 109, 214, 1);
                transform: rotate(-90deg);
            }
        }
        &.link-icon-mob{
            transition: $transition-fast;
            @include flex-center();
            gap: 4px;
            .icon-mob{
                opacity: 0;
                visibility: hidden;
            }
            &:hover{
                .icon-mob{
                    opacity: 1;
                    visibility: visible;
                    stroke: rgba(86, 109, 214, 1);
                    transform: rotate(-90deg);
                }
            }
        }

        &.submenu-mob{
            display: flex;
            gap: 4px;
            align-items: center;
        }
        .icon-mob{
            transition: 0.15s;
            stroke: rgb(255, 255, 255);

        }
    }
}
.subitems-wrapper-mob{
    transition: $transition-fast;
    overflow: hidden;
    height: 0;

    &.open-mob{
        height: 100%;
        opacity: 1;
        overflow: auto;
    }

    .subitems-mob{
        padding: 8px;
        @include flex-column();
        gap: 4px;
    }
}