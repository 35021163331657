@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
$solid-shadow: 0px 10px 24px 0px #7b68ee52;
$solid-shadow-hover:
  0px 1px 3px 1px #002eff26,
  0px 1px 2px 0px #002eff4d;
$outlined-shadow-hover:
  0px 1px 3px 1px #002eff26,
  0px 1px 2px 0px #002eff4d;
$outlined-shadow: 0px 10px 24px 0px #7b68ee52;
$shadow-shadow: 0px 10px 24px 0px #7b68ee52;

.button {
  width: fit-content;
  height: fit-content;
  padding: 6px 14px;
  cursor: pointer;
  border-radius: 32px;

  @include text-Button();

  &.purple {
    border: 1px solid rgba($color-purple, 0);
    background: $color-purple;
    box-shadow: $solid-shadow;

    @include touch-hover(&) {
      background: $color-purple-darkness;
      box-shadow: $solid-shadow-hover;
    }

    &:active {
      box-shadow: none;
    }

    &:focus-visible {
      border: 1px solid rgba($color-purple-lighter, 1);
      background: $color-purple-darkness;
    }

    &:disabled {
      background: $color-purple-lighter;
      box-shadow: none;
    }
  }

  &.outlined {
    color: #566dd6;
    transition: 0.3ms;
    border: 1px solid #e0e0ff;
    background: #fff;
    &:hover {
      background-color: #e76325;
      color: #fff;
    }
    &:disabled {
      background-color: #f4f4f4;
      opacity: 0.2;
      span{
        color: #000000;
      }

    }
  }
  &.filed {
    background-color: transparent;
    border: none;
    color: #7e7c7a;
    transition: 0.3ms;
    height: 58px;
    &:hover {
      color: #e76325;
    }
    &:disabled {
      border: 1px solid #f4f4f4;
      color: #bdbdbd;
    }
  }
  &.empty {
    background-color: transparent;
    border: 1px solid #fff;
    color: #fff;
    transition: 0.3ms;
    &:hover {
      color: #566dd6;
    }
    &:disabled {
      border: 1px solid #f4f4f4;
      color: #bdbdbd;
    }
  }
}
