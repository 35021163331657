@import "@/shared/styles/breakpoints.scss"; @import "@/shared/styles/mixins.scss"; @import "@/shared/styles/variables.scss"; 
.hidden{
  opacity: 0;
  pointer-events: none;
}
.logo{
  cursor: pointer;

  &.isMobile{
    width: 15px;
    height: 15px;

  }
  .image{
    width: 285px;
    height: 20px;

    &.isMobile{
      width: 15px;
      height: 15px;

    }
  }
}